<template>
    <div class="wrap padding-top-layout">
        <div class="wrap__block">
            <div class="wrap__block__header wrap__header">
                <div class="wrap__block__header__content">
                    <span>{{ $t("group_enterprise_management.title_enterprise_management") }}</span>
                    <div class="helper-icon">
                        <img 
                        :src="getSettingIcon('helper-icon.svg')" 
                        @mouseover="changeTooltipImage($event)"
                        @mouseleave="changeTooltipImage($event, 'mouseleave')" alt="" 
                        />
                        <div v-if="showTooltip" class="helper-icon tooltip"
                            v-html="$t('group_enterprise_management.tooltip_title_enterprise_management')"></div>
                    </div>
                </div>
            </div>
            <div class="wrap__status">
                <div class="wrap__status__item">
                    <img src="@/assets/icons/clock.svg" alt="" />
                    <div class="wrap__status__item__wrap">
                        <p>{{$t('common.last_update')}}</p>
                        <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
                    </div>
                </div>
                <div class="wrap__status__item">
                    <img src="@/assets/icons/userTable.svg" alt="" />
                    <div class="wrap__status__item__wrap">
                        <p>{{$t('common.updated_by')}}</p>
                        <p>{{ statusUpdate.user || "" }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="category-table main-table custom-table enterprise-table"
            :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']">
            <data-table
                :data-source="enterpriseList"
                :init-grid="initializeGrid"
                :grid-columns="gridColumns"
                :totalData="totalData"
                :rowFocus="2"
                :isHasData="isHasData"
                :cellInput="cellInput"
                :allowRightClick="true" 
                @changeFullScreen="onChangeFullScreen"
                @onHandleActionTable="checkValidationData"
                @flexGridInitialDone="onFlexGridInitialDone"
                @updateAutoFill="updateAutoFill"
            />
        </div>
        <dialog-popup 
            :dialog="dialogPopup"
            :message="dialogMessage"
            @submit="() => checkboxHandler(false)"
            @close="closePopup" />
        <notification-popup 
            :dialog="dialogNotification"
            :message="messageSavedChange"
            @submit="dialogNotification = false" />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
    getListGroupEnterpriseApi,
    updateGroupEnterprise,
    createGroupEnterprise,
} from "@/api/group-enterprise";
import { Tooltip, PopupPosition, CollectionView } from "@mescius/wijmo";
import * as wjGrid from "@mescius/wijmo.grid";
import * as wjcCore from "@mescius/wijmo";
import DialogPopup from "@/components/dialogs/question-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { validateMaximumCharactor, validateBlankRowData } from "@/utils/validate";
import { formatDateTime } from "@/utils/datetimeFormat";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import debounce from "lodash/debounce";
import { UndoStack } from "@mescius/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { batchReplacementViewCollection, emptyPromise } from "@/concerns/registerData/wijmo.helper";
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth'
import { $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
export default {
    data() {
        return {
            items: [
                {
                    text: this.$t('group_enterprise_management.hyperlink_home_enterprise_management'),
                    disabled: false,
                    href: ROUTES.HOME_DASHBOARD
                },
                {
                    text: this.$t('group_enterprise_management.hyperlink_setting_enterprise_management'),
                    disabled: false,
                    href: ROUTES.SETTING
                },
                {
                    text: this.$t('group_enterprise_management.hyperlink_text_enterprise_management'),
                    disabled: true,
                    href: ROUTES.group_enterprise_management
                }
            ],
            flex: null,
            gridColumns: [],
            dialogPopup: false,
            dialogMessage: this.$t('group_enterprise_management.description_dialog_message'),
            dialogCancelBtn: false,
            check_status: null,
            hdrTips: new Tooltip({
                position: PopupPosition.Above,
                showAtMouse: true,
                showDelay: 600,
                cssClass: "hdr-tip"
            }),
            enterpriseList: null,
            groupEnterpriseData: [],
            idsUpdate: [],
            dataRedo: [],
            statusUpdate: {
                time: null,
                user: null
            },
            totalData: null,
            undoStack: null,
            canUndo: false,
            canRedo: false,
            actionCount: 0,
            stackUndoWhenUncheck: null,
            eventChangeStatus: null,
            showTooltip: false,
            listOriginalData: [],
            dialogNotification: false,
            cellInput: [
                'business_group_name',
                'business_name',
            ],
            celEnabel: [],
            isEditDataTable: 0
        };
    },

    components: {
        DataTable,
        DialogPopup,
        NotificationPopup,
    },

    computed: {
        ...mapState("userData", ["currentUser"]),
        ...mapState("commonApp", ["loadMore", "isFilter", "isExpand", "statusAction"]),
        ...mapState("actionsTable", ["startAction"]),
        ...mapState("actionsTable", ["dataAction"]),
        ...mapState("registerData", ["isFullScreen"]),

        isHasData() {
            return this.isEditDataTable ? true : false
        },
        messageSavedChange() {
            return this.$t('group_enterprise_management.message_saved_changes')
        }
    },

    async mounted() {
        this.actionUpdateIsLoading(true)
        this.updateBreadCrumb(this.items);
        this.gridColumns = this.getGridColumns();
        await this.getEnterpriseList();
        this.createUndoStack();
        this.$nextTick(() => {
            this.actionUpdateIsLoading(false)
        })
        this.celEnabel = ['business_group_name', 'business_name']
    },

    watch: {
        startAction(newValue, _) {
            if (newValue.undo) {
                this.undoStack.undo();
            } else if (newValue.redo) {
                this.undoStack.redo();
            }
        },

        canUndo(newValue) {
            this.actionUpdateStatusBtn({
                undo: newValue,
                redo: this.canRedo
            });
        },

        canRedo(newValue) {
            this.actionUpdateStatusBtn({
                undo: this.canUndo,
                redo: newValue
            });
        }
    },

    methods: {
        ...mapActions("commonApp", ["updateBreadCrumb", "updateStatusAction", "actionUpdateIsLoading"]),
        ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),
        ...mapActions("registerData", ["actionUpdateIsFullScreen"]),

        initializeGrid(flexgrid) {
            this.flex = flexgrid;

            flexgrid.scrollPositionChanged.addHandler(
                debounce((s, e) => {
                    if (!this.$store.state.registerData.isFullScreen) {
                        return;
                    }

                    if (s.viewRange.bottomRow >= s.rows.length - 1) {
                        s.deferUpdate(() => {
                            const lastClientId = flexgrid.itemsSource.itemCount;

                            for (let index = 1; index <= 100; index++) {
                                s.itemsSource.addNew(this.blankData(lastClientId + index));
                            }

                            s.itemsSource.commitNew();
                            s.itemsSource.clearChanges();
                        });
                    }
                }, 100)
            );
            document.addEventListener("keydown", (e) => {
                if (
                    (e.metaKey || e.ctrlKey) &&
                    [
                        KEYS_CODE.DOWN_ARROW,
                        KEYS_CODE.UP_ARROW,
                        KEYS_CODE.LEFT_ARROW,
                        KEYS_CODE.RIGHT_ARROW,
                        KEYS_CODE.ENTER
                    ].includes(e.keyCode)
                ) {
                    e.preventDefault();
                }
            });

            flexgrid.hostElement.addEventListener(
                "keydown",
                (e) => {
                    // console.log('keydown: ', e);
                    if (e.metaKey || e.ctrlKey) {
                        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
                            const currentSelection = flexgrid.selection;
                            const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
                            flexgrid.selection = cellRange;

                            // re-select after add more
                            setTimeout(() => {
                                flexgrid.selection = cellRange;
                            }, 200);
                        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
                            const currentSelection = flexgrid.selection;
                            const cellRange = new wjGrid.CellRange(0, currentSelection.col);
                            flexgrid.selection = cellRange;
                        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
                            const currentSelection = flexgrid.selection;
                            const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
                            flexgrid.selection = cellRange;
                        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
                            const currentSelection = flexgrid.selection;
                            const cellRange = new wjGrid.CellRange(currentSelection.row, 2);
                            flexgrid.selection = cellRange;
                        }
                    }

                    if (e.keyCode === KEYS_CODE.ENTER) {
                        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
                            const lastClientId = flexgrid.itemsSource.itemCount;

                            flexgrid.deferUpdate(() => {
                                flexgrid.itemsSource.addNew(this.blankData(lastClientId + 1));
                                flexgrid.itemsSource.commitNew();
                                flexgrid.itemsSource.clearChanges();
                            });
                        }
                    }
                },
                false
            );

            flexgrid?.cellEditEnded.addHandler((s, e) => {
                let oldValue = e.data;
                let newValue = s.getCellData(e.row, e.col);
                this.checkTableChangeData();
                const { row } = e.range;
                const view = s.collectionView;
                const source = view.sourceCollection;
                const currentItem = source[row] || {};
                this.handleConvertFullWidthToHaftWidth(s, e);
                if (this.isItemActive(currentItem)) {
                    currentItem.status = true
                }

                if (
                    s.columns[e.col].binding !== "business_group_name" ||
                    s.columns[e.col].binding !== "business_name"
                ) {
                    return;
                }
            });

            flexgrid?.beginningEdit.addHandler((s, e) => {
                let column = s.columns[e.col];
                let rowValue = s.rows[e.row]._data;
                this.check_status = rowValue;

                if (column.binding !== "status") {
                    return;
                }

                this.stackUndoWhenUncheck = new GridEditAction(s, e);
                this.handleCheckBox(rowValue);
                e.cancel = true;
            });

            flexgrid?.formatItem.addHandler((s, e) => {
                const colBinding = e.panel.columns[e.col].binding;
                const arr = [
                    "business_name",
                    "business_group_name",
                    "status",
                ];
                if (e.panel == s.columnHeaders) {
                    if (!arr.includes(colBinding)) {
                        return null;
                    }
                    if (colBinding === "status") {
                        toolTipCustom(
                            e,
                            "t10",
                            this.$t('group_enterprise_management.tooltip_table_column_status_enterprise'),
                            this.hdrTips
                        );
                    }
                }
            });

            flexgrid.pasted.addHandler((s, e) => {
                const { row, row2 } = e.range;
                const dataPasted = s.collectionView.sourceCollection.filter((item, index) => index >= row && index <= row2);
                dataPasted.forEach(rowItem => {
                    if (this.isItemActive(rowItem)) {
                        rowItem.status = true
                    }
                })
                this.checkTableChangeData();
            });
        },
        handleConvertFullWidthToHaftWidth(s, e) {
            const { row, col } = e.range;
            let cellData = s.getCellData(row, col, false)?.toString();
            const isNumber = $_helper_isNumberType(cellData);
            cellData = cellData && isNumber ? cellData : convertNestedFullWidthToHalfWidth(cellData);
            s.setCellData(row, col, cellData, false, true);
        },

        defineTable() {
            this.gridColumns = this.getGridColumns();
            this.initialView();

            this.$nextTick(() => {
                this.scrollToTop();
            });
        },

        emptyCell(item) {
            if (item === undefined || item === null || item === '') {
                return this.$t('validation.error_required_field');
            }
            return null;
        },

        getError(item, propName) {
            if (validateBlankRowData(item, ['business_group_name', 'business_name'])) {
                return null;
            }
            switch (propName) {
                case "business_group_name":
                    return this.emptyCell(item[propName]);
                case "business_name":
                    return validateMaximumCharactor(item[propName], 128);
            }
            return null;
        },
        getTextWidth(string) {
            return getWidthByTextContent(string) + 87; // 6 + 18 + 53 + 10 (6 = margin left tooltip, 18: width tooltip, 53: icon, 10: default number margin)
        },
        getGridColumns() {
            return [
                {
                    header: "#",
                    binding: "id",
                    allowSorting: false,
                    isReadOnly: true,
                    visible: false
                },
                {
                    header: this.$t('group_enterprise_management.table_column_group_name_enterprise'),
                    binding: "business_group_name",
                    minWidth: 154,
                    minWidth: getWidthByTextContent(this.$t('group_enterprise_management.table_column_group_name_enterprise')),
                    maxWidth: 980,
                    allowSorting: false,
                    isRequired: false,
                    wordWrap: true,
                    cssClassAll: "single-row"
                },
                {
                    header: this.$t('group_enterprise_management.table_column_enterprise_name_enterprise'),
                    binding: "business_name",
                    minWidth: getWidthByTextContent(this.$t('group_enterprise_management.table_column_enterprise_name_enterprise')),
                    maxWidth: 980,
                    allowSorting: false,
                    isRequired: false,
                    wordWrap: true,
                    cssClassAll: "single-row"
                },
                {
                    header: this.$t('group_enterprise_management.table_column_status_enterprise'),
                    binding: "status",
                    minWidth: getWidthByTextContent(this.$t('group_enterprise_management.table_column_status_enterprise')),
                    width: '*',
                    allowSorting: false,
                    isRequired  : false,
                    dataType: "Boolean",
                    wordWrap: true,
                    cssClassAll: "single-row"
                }
            ];
        },
        handleCheckBox(value) {
            if (value?.status) {
                this.dialogPopup = true;
                this.dialogCancelBtn = true;
            } else {
                this.checkboxHandler(true);
            }
        },

        checkboxHandler(check) {
            if (this.check_status.id === undefined || !this.check_status.business_group_name || !this.check_status.business_name) {
                return;
            }
            this.isEditDataTable = true
            this.enterpriseList.beginUpdate();
            this.enterpriseList.currentItem.status = check;
            this.enterpriseList.endUpdate();
            if (this.stackUndoWhenUncheck) {
                this.undoStack.pushAction(this.stackUndoWhenUncheck);
                this.stackUndoWhenUncheck = null;
            }
            this.closePopup();
        },

        closePopup() {
            this.dialogPopup = false;
            this.dialogCancelBtn = true;
        },

        dateTimeFormat(dateTime) {
            return formatDateTime(dateTime);
        },
        getSettingIcon(image) {
            if (image) {
                return require(`@/assets/icons/${image}`);
            }
            return "";
        },

        changeTooltipImage(event, type) {
            if (type === "mouseleave") {
                event.target.src = this.getSettingIcon("helper-icon.svg");
                event.target.parentElement.style.cursor = "default";
                this.showTooltip = false;
            } else {
                event.target.src = this.getSettingIcon("helper-icon_active.svg");
                event.target.parentElement.style.cursor = "pointer";
                this.showTooltip = true;
            }
        },
        blankData(clientRowId) {
            return {
                clientRowId: clientRowId,
                id: BLANK_ID,
                business_name: null,
                business_group_name: null,
                status: null
            };
        },
        addBlankItemsToView(count) {
            const lastClientId = this.enterpriseList.itemCount;
            for (let index = 1; index <= count; index++) {
                this.enterpriseList.addNew(this.blankData(lastClientId + index));
            }

            this.enterpriseList.commitNew();
            this.enterpriseList.clearChanges();
        },
        scrollToTop() {
            if (!this.flex) {
                return;
            }

            let rc = this.flex.cells.getCellBoundingRect(0, 0, true);
            this.flex.scrollPosition = new wjcCore.Point(this.flex.scrollPosition.x, -rc.top);
        },
        initialView() {
            if (this.enterpriseList) {
                this.groupEnterpriseData = this.enterpriseList.items.filter((item) => item.id && item.id !== BLANK_ID);
            }

            this.enterpriseList = new CollectionView([...this.groupEnterpriseData], {
                trackChanges: true,
            });
            this.addBlankItemsToView(10);
            this.listOriginalData = JSON.parse(JSON.stringify(this.enterpriseList.items));

            this.flex.columnGroups = this.gridColumns;
            this.enterpriseList.currentItem = null;
            setMinMaxSizeColumns(this.flex, this.groupEnterpriseData);
            this.enterpriseList.collectionChanged.addHandler(() => {
                this.enterpriseList?.itemsAdded?.forEach((itemAdd) => {
                    if (this.isItemActive(itemAdd)) {
                        itemAdd.status = true
                    }
                })
            })
        },
        // TODO
        isValidGroupEnterprise(item) {
            return !(!item?.business_name ||
                !item?.business_group_name);
        },
        onChangeFullScreen(isFullScreen) {
            if (isFullScreen) {
                this.addBlankItemsToView(100);
                this.scrollToTop();
            } else {
                this.$nextTick(() => {
                    this.scrollToTop();
                });
            }
        },
        onFlexGridInitialDone(flexgrid) {

            if (!flexgrid) {
                return;
            }
            this.flexgrid = flexgrid;

            this.canUndo = false;
            this.canRedo = false;

            const stateChanged = (e) => {
                this.canUndo = e.canUndo;
                this.canRedo = e.canRedo;
            };

            this.undoStack = new UndoStack(flexgrid, stateChanged);
        },

        createUndoStack() {
            this.undoStack = new UndoStack("#undoable-table", {
                maxActions: 50,
                stateChanged: (s) => {
                    this.canUndo = s.canUndo;
                    this.canRedo = s.canRedo;
                }
            });
        },
        checkValidationData() {
            this.enterpriseList.getError = this.getError;
            this.submitData();
        },
        submitData() {
            let edited = {};
            let editedBlank = {};
            let added = {};

            let self = this;
            let indexBlank = 0;
            self.listOriginalData.forEach(function (item) {
                let newItemIndex = self.enterpriseList.items.findIndex(currentItem => currentItem.id === item.id);
                const indexWithBlankId = newItemIndex + indexBlank;
                let enterpriseItem = self.enterpriseList.items[indexWithBlankId];
                if (enterpriseItem?.status !== item?.status ||
                    enterpriseItem?.business_name !== item?.business_name ||
                    enterpriseItem?.business_group_name !== item?.business_group_name
                ) {
                    let prepareBranch = {
                        id: enterpriseItem?.id,
                        business_name: enterpriseItem?.business_name,
                        business_group_name: enterpriseItem?.business_group_name,
                        status: enterpriseItem?.status === null ? 1 : enterpriseItem?.status ? 1 : 0
                    };
                    const indexPayload = self.enterpriseList.items.indexOf(enterpriseItem);
                    if (indexPayload !== -1) {
                        prepareBranch['index'] = indexPayload + 1;
                    }
                    if (enterpriseItem.hasOwnProperty("orderId")) {
                        delete (prepareBranch.orderId);
                    }
                    if (!self.isValidGroupEnterprise(prepareBranch)) {
                        return;
                    }
                    if (enterpriseItem.id === BLANK_ID) {
                        delete prepareBranch.id;
                        editedBlank[indexWithBlankId] = prepareBranch;
                        indexBlank += 1
                    } else {
                        edited[indexWithBlankId] = prepareBranch;
                    }
                }
            });

            this.enterpriseList.itemsAdded.forEach((enterpriseItem, index) => {
                let itemCustom = {
                    business_group_name: enterpriseItem.business_group_name,
                    business_name: enterpriseItem?.business_name || null,
                    status: enterpriseItem.status === false ? 0 : 1
                };

                const indexPayload = this.enterpriseList.items.indexOf(enterpriseItem);
                if (indexPayload !== -1) {
                    itemCustom['index'] = indexPayload + 1;
                }
                if (enterpriseItem.hasOwnProperty("orderId")) {
                    delete (itemCustom.orderId);
                }
                if (!this.isValidGroupEnterprise(itemCustom)) {
                    return;
                }
                added[index] = itemCustom;
            });

            // call api
            this.actionUpdateIsLoading(true)
            const editedPromise = Object.values(edited).length ? updateGroupEnterprise({ data: Object.values(edited) }).then(() => {
                this.listOriginalData = JSON.parse(JSON.stringify(this.enterpriseList.items));
                this.dialogNotification = true;
                this.isEditDataTable = false;
                this.undoStack.clear();
                this.actionUpdateIsLoading(false)
            })
            .catch((error) => {
                console.warn(error);
            }) : emptyPromise();

            const editedBlankPromise = Object.values(editedBlank).length ? createGroupEnterprise({ data: Object.values(editedBlank) }).then((res) => {
                this.afterDataSuccess(this.enterpriseList.itemsEdited, editedBlank, res);
            }).catch((error) => {
                console.warn(error);
            }) : emptyPromise();

            const addedPromise = Object.values(added).length ? createGroupEnterprise({ data: Object.values(added) }).then((res) => {
                this.afterDataSuccess(this.enterpriseList.itemsAdded, added, res);
            }).catch((error) => {
                console.warn(error);
            }) : emptyPromise();
            Promise.all([editedPromise, editedBlankPromise, addedPromise]).then(() => {
                this.actionUpdateIsLoading(false)
            }).catch(() => {
                this.actionUpdateIsLoading(false)
            });
        },
        afterDataSuccess(collection, items, response) {
            const addedReplacement = {
                id: "id",
                status: "status"
            };
            this.statusUpdate = {
                time: response.latest_record.updated_at_latest,
                user: response.latest_record.user_updated
            };
            this.enterpriseList.deferUpdate(() => {
                batchReplacementViewCollection(collection, response.record_insert, Object.keys(items), addedReplacement);
                collection.length = 0;
            });
            this.listOriginalData = JSON.parse(JSON.stringify(this.enterpriseList.items));
            this.dialogNotification = true;
            this.undoStack.clear();
            this.actionUpdateIsLoading(false)
        },
        isItemActive(item) {
            return this.celEnabel.every(key => item[key]) && (!item.id || item.id === BLANK_ID)
        },
        async getEnterpriseList() {
            await getListGroupEnterpriseApi().then((responseApiListBranch) => {
                this.groupEnterpriseData = responseApiListBranch.data?.map((item, index) => item && {
                    ...item,
                    index: index + 1
                });
                this.totalData = this.groupEnterpriseData.length;
                // TODO: CHECK {{$t('common.last_update')}}
                this.statusUpdate = {
                    time: responseApiListBranch.latest_record.updated_at_latest,
                    user: responseApiListBranch.latest_record.user_updated
                };
            });
            this.defineTable(this.groupEnterpriseData);
        },
        checkTableChangeData() {
            setTimeout(() => {
                this.isEditDataTable = false;
                const itemsInView = ['business_group_name', 'business_name']
                this.enterpriseList?.itemsEdited.some((item) => {
                    const everyColumnsAreNull = itemsInView.every(
                        (field) => item[field] === null || item[field] === '' || item[field] === undefined,
                    );
                    if (!everyColumnsAreNull) {
                        this.isEditDataTable = true;
                        return true;
                    }
                });

                this.enterpriseList?.itemsAdded.some((item) => {
                    const everyColumnsAreNull = itemsInView.every(
                        (field) => item[field] === null || item[field] === '' || item[field] === undefined,
                    );
                    if (!everyColumnsAreNull) {
                        this.isEditDataTable = true;
                        return true;
                    }
                });
            }, 100)
        },
        updateAutoFill(rowItem) {
            if (this.isItemActive(rowItem)) {
                rowItem.status = true
            }
            this.checkTableChangeData();
        }
    },
};
</script>

<style scoped lang="scss">
@import './styles/index.scss';
</style>

